import React from 'react';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import ReactGA from 'react-ga4';
import { RWebShare } from 'react-web-share';
import { FiShare } from 'react-icons/fi';
import { BiArrowBack } from 'react-icons/bi';
import Localization from '../localization';
import { getVictoriaAge } from '../utils';

const conf = require('../config/web-site');

const strings = Localization.getStrings();

export enum Buttons {
  back,
  share
}

type NavBarProps = {
  buttons: Buttons[];
  config?: typeof conf;
}

function NavBar(props: NavBarProps) {
  const navigate = useNavigate(); 
  const config = props.config || conf;

  const { buttons } = props;

  const renderShareButton = () => {
    const description = strings.t_description.replace('{age}', Localization.translateNumber(getVictoriaAge()));

    return (
      <RWebShare
      closeText={strings.t_close}
        data={{
          text: `${config.title}\n${description.charAt(0).toUpperCase()}${description.slice(1)}`,
          url: config.url,
          title: config.title
        }}
        onClick={ () => {
          ReactGA.event({
            category: config.analytics.parameters.categories.navigation,
            action: 'Share Button Click',
            label: 'website clicks'
          });
        }}
      >
        <Nav.Item data-testid='share-button'>
          <Nav.Link>
            <FiShare size={21} className='icon' data-testid='share-button-icon' />
          </Nav.Link>
        </Nav.Item>
      </RWebShare>
    );
  }

  return (
    <Nav>
    { buttons.includes(Buttons.back) &&
      <Nav.Item data-testid='back-button' onClick={() => {
        ReactGA.event({
          category: config.analytics.parameters.categories.navigation,
          action: 'Back Button Click',
          label: 'website clicks'
        });
        navigate(config.pages.home.route);
      }}>
        <Nav.Link>
          <BiArrowBack size={20} className='icon' data-testid='back-button-icon'  />
        </Nav.Link>
      </Nav.Item>
    }
    { buttons.includes(Buttons.share) && renderShareButton() }
    </Nav>
  );
}

export default NavBar;