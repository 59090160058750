import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import NavBar, { Buttons } from '../components/NavBar';
import LangNavBar from '../components/LangNavBar';
import Localization from '../localization';
import { getVictoriaAge, processParagraphs } from '../utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/About.scss';

type Shows = {
  [key: string]: {
    location: string;
    date: string;
  };
}
const conf = require('../config/web-site');
const strings = Localization.getStrings();

const filterSorShows = (shows: Shows) => {
  return Object.entries(shows).filter(show =>
    show[1].location === 'SOR'
  ).length;
}

function About(props: { config?: typeof conf }) {
  const config = props.config || conf;

  const shows = filterSorShows(config.shows);

  ReactGA.send({ 
    hitType: config.analytics.parameters.hitType, 
    page: config.pages.about.route, 
    title: config.pages.about.title 
  });
  return (
    <div className='general-container'>
		<div className='nav-left'><NavBar buttons={[Buttons.back]}></NavBar></div>
    <div className='nav-right'><LangNavBar></LangNavBar></div>
      <Container fluid className='about'>
      <Row>
        <Col data-testid='about-content'>
          <h1>{strings.t_title}</h1>
          <h6>{strings.t_pronouns}</h6>
        { 
          processParagraphs(strings.t_about
          .replace('{age}', Localization.translateNumber(getVictoriaAge()))
          .replace('{shows}', Localization.translateNumber(shows)))
        } 
        </Col>
      </Row>
      </Container>
    </div>
  );
}

export default About;
