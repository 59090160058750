import { Language } from './language';

const zh = {
    code: Language.zh,

	// HOME PAGE
	
	t_description: '女鼓手，{age}岁',
	t_close: '关闭',
	
	b_about: '关于我',
	b_press: '按',
	b_shows: '演出',
	b_language: '中国人',
	b_share: '分享',

	t_english: '英语',
	t_spanish: '西班牙语',
	t_portuguese: '葡萄牙语',
	t_italian: '意大利语',
	t_french: '法语',
	t_german: '德语',
	t_chinese: '中国人',

	// SHOWS PAGE

	BlackViolinConcert_title: '与世界你的乐团的黑色小提琴音乐会',
	BlackViolinConcert_description: '派拉蒙剧院',

	MexicanIndependenceDay2024_title: '巴拉德市民管弦乐团庆祝墨西哥独立日',
	MexicanIndependenceDay2024_description: '西雅图市政厅',

	BluesRock_title: '蓝调摇滚',
	BluesRock_description: '西雅图摇滚学校',

	BallardSeaFoodFest2024_title: '二零二四 巴拉德海鲜节',
	BallardSeaFoodFest2024_description: '巴拉德海鲜节',

	Funk_title: '放克',
	Funk_description: '西雅图摇滚学校',

	Heart_title: '心',
	Heart_description: '西雅图摇滚学校',

	ModernMetal_title: '现代金属',
	ModernMetal_description: '西雅图摇滚学校',

	SeasonPreviewWinter2023_title: '二零二三 年冬季季节预览',
	SeasonPreviewWinter2023_description: '西雅图摇滚学校',

	SmashingPumpkins_title: '粉碎南瓜',
	SmashingPumpkins_description: '西雅图摇滚学校',

	Nirvana_title: '涅槃',
	Nirvana_description: '西雅图摇滚学校',

	HairMetal_title: '头发金属',
	HairMetal_description: '西雅图摇滚学校',

	TalentShow2023_title: '二零二三才艺展示',
	TalentShow2023_description: '西雅图鲑鱼湾小学',

	Deftones_title: '去色调',
	Deftones_description: '西雅图摇滚学校',

	SeasonPreviewSpring2023_title: '二零二三 年春季季前瞻',
	SeasonPreviewSpring2023_description: '西雅图摇滚学校',

	Ramones_title: '雷蒙斯',
	Ramones_description: '西雅图摇滚学校',

	Soundtrack_title: '配乐秀',
	Soundtrack_description: '西雅图摇滚学校',

	PopPunkVsEmo_title: '流行朋克与。 情绪',
	PopPunkVsEmo_description: '西雅图摇滚学校',

	_90sNostalgia_title: '九零年代怀旧',
	_90sNostalgia_description: '西雅图摇滚学校',

	TalentShow2022_title: '二零二二才艺展示',
	TalentShow2022_description: '西雅图鲑鱼湾小学',

	// PRESS PAGE

	the_daly_migs_show_interview_title: '达利米奇秀采访',
	the_daly_migs_show_interview_description: '关于受邀加入 Godsmack 舞台的采访',

	alizee_defan_07_02_2024_title: '《艾莉婕·德凡》专访',
	alizee_defan_07_02_2024_description: '独家专访',

	jaime_mendez_news_06_26_2024_title: '《海梅·门德斯新闻》采访',
	jaime_mendez_news_06_26_2024_description: '西雅图当地西班牙语新闻',

	bien_informado_03_22_2023_title: '“消息灵通" 文章',
	bien_informado_03_22_2023_description: '阿根廷新闻网站关于维多利亚鼓的文章',

	wave_potter_reaction_01_30_2023_title: '波波特 反应',
	wave_potter_reaction_01_30_2023_description: '波波特 对 胜利 的 永恒之光 封面做出反应',

	// ABOUT PAGE

	b_back: '回去',

	t_title: 'Victoria Acosta',
	t_pronouns: '她',

	t_about: `
维多利亚·阿科斯塔 (Victoria Acosta) 是一位来自德克萨斯州埃尔帕索的 {age} 岁鼓手，她充满活力的精神和对音乐的不懈热情正在席卷全球！维多利亚出生于墨西哥华雷斯城，童年在瓜达拉哈拉的繁华街道上度过，她对鼓的迷恋在她四岁时就被点燃了。当大多数孩子忙着玩玩具时，这位非凡的天才却忙着敲打节奏，并向家人宣称鼓是她最喜欢的乐器。

2018 年 9 月 13 日，她的梦想成真了，她拆开了她的第一套鼓，从那一刻起，她就停不下来了！她进入了位于瓜达拉哈拉的著名音乐学院 Musicalerias，正式开始上课，像真正的摇滚明星一样掌握了节奏的艺术。

2019 年 10 月 10 日，维多利亚的家庭搬到了充满活力的西雅图市，新的冒险开始了。她并没有因此气馁，而是转向在线学习，证明了距离无法阻挡一位热情的音乐家。2022 年 5 月 29 日，她的音乐之路迎来了一个重大突破——她在著名的 School of Rock 参加了一次试听课。虽然大多数同龄人都从初学者的“Rock 101”项目开始，但维多利亚的才华脱颖而出，使她赢得了进入高级表演者项目的机会。从那时起，她在 {shows} 场现场演出中闪耀登场，以她充满感染力的能量和舞台魅力震撼观众。

但这仅仅是个开始！维多利亚不仅是一名表演者，她还是社交媒体上的一颗新星，已经录制了数百部展示她鼓技的精彩视频。2024 年 6 月 13 日，她作为意大利著名作曲家阿尔贝托·里贡尼（Alberto Rigonni）的嘉宾鼓手，在他的新专辑《Nemesis Call》的歌曲研讨会上大放异彩，这证明了她上升的明星地位！

在一个令人振奋的里程碑时刻，维多利亚于 2024 年 9 月 12 日签署了与 Soultone Cymbals 的代言协议，巩固了她在鼓手精英中的地位，并将她的音色提升到了一个新的高度！

不仅如此，维多利亚还在四年级时扩展了她的音乐视野，学习了小提琴。2024 年 5 月 13 日，她加入了世界青年交响乐团（World Youth Orchestra），并有幸与著名二人组 Black Violin 一同在 Paramount Theatre 演出，以她非凡的才华迷倒了 2,000 名观众。

她的音乐旅程并未就此止步！2024 年 8 月 29 日，她作为打击乐手加入了巴拉德市民交响乐团（Ballard Civic Orchestra），并在西雅图市政厅的墨西哥独立日庆祝活动中首次亮相，面向近 1,000 名热情的观众演出。

维多利亚的荣誉同样令人印象深刻。她在 2022 年伦敦青年音乐家大赛中获得金奖和“精彩技术”奖，并在 2023 年伦敦年度青年音乐家比赛中荣获“年度明星音乐家奖”和“年度杰出鼓手特别奖”。

怀着对成长的无限渴望，维多利亚每天都在练习，不断突破自己在打鼓和小提琴上的能力。这位充满活力的年轻艺术家眼中闪耀着对音乐未来的无限向往，她的音乐之旅才刚刚开始。准备好被维多利亚·阿科斯塔所激励吧——在这里，节奏与激情相遇，梦想成真！
	`
}

export default zh;
