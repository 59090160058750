import { Language } from './language';

const fr = {
    code: Language.fr,

	// HOME PAGE
	
	t_description: 'Fille batteuse de {age} ans',
	t_close: 'Fermer',

	b_about: 'Sur moi',
	b_press: 'Presse',
	b_shows: 'Montre',
	b_language: 'Français',
	b_share: 'Partager',

	t_english: 'Anglaise',
	t_spanish: 'Espagnole',
	t_portuguese: 'Portugais',
	t_italian: 'Italien',
	t_french: 'Français',
	t_german: 'Allemand',
	t_chinese: 'Chinois',

	// SHOWS PAGE

	BlackViolinConcert_title: 'Concert De Violon Noir Avec Le Monde Votre Orchestre',
	BlackViolinConcert_description: 'Le Théâtre Paramount',

	MexicanIndependenceDay2024_title: 'Célébration du Jour de l\'Indépendance du Mexique avec le Ballard Civic Orchestra',
	MexicanIndependenceDay2024_description: 'Hôtel de ville de Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival des fruits de mer Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival des fruits de mer Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',
	
	ModernMetal_title: 'Métal moderne',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Aperçu de la saison hiver 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Cheveux Métal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Spectacle de talents 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Aperçu de la saison printemps 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Les Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Spectacle de la bande originale',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgie des années 90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Spectacle de talents 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	the_daly_migs_show_interview_title: 'L\'interview du Daly Migs Show',
	the_daly_migs_show_interview_description: 'Interview sur le fait d\'être invité à rejoindre Godsmack sur scène',

	alizee_defan_07_02_2024_title: 'Entretien avec "Alizée Defan"',
	alizee_defan_07_02_2024_description: 'Interview exclusive',

	jaime_mendez_news_06_26_2024_title: 'Interview "Jaime Mendez Nouvelles"',
	jaime_mendez_news_06_26_2024_description: 'Nouvelles locales de Seattle en espagnol',

	bien_informado_03_22_2023_title: 'Article de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Article du site d\'information argentin sur Victoria',

	wave_potter_reaction_01_30_2023_title: 'La réaction de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter réagit à la reprise de Victoria Lux Æterna de Metallica',

	// ABOUT PAGE

	b_back: 'Retourner',

	t_title: 'Victoria Acosta',
	t_pronouns: '(elle)',

	t_about: `
Rencontrez Victoria Acosta, la batteuse électrisante de {age} ans originaire d’El Paso, au Texas, dont l’esprit vibrant et la passion inébranlable pour la musique ont conquis le monde entier ! Originaire de Ciudad Juarez, au Mexique, et ayant passé son enfance dans les rues animées de Guadalajara, la fascination de Victoria pour la batterie s’est enflammée à l’âge de quatre ans. Alors que la plupart des enfants étaient occupés à jouer avec des jouets, cette talentueuse joueuse était occupée à taper des rythmes et à déclarer la batterie comme son instrument préféré à sa famille.

Le 13 septembre 2018, son rêve est devenu réalité lorsqu'elle a déballé sa toute première batterie, et à partir de ce moment, rien ne pouvait l'arrêter ! Elle s'est inscrite à la prestigieuse Académie Musicalerias à Guadalajara, où elle s'est plongée dans des leçons formelles, maîtrisant l'art du rythme comme une vraie rock star.

Le 10 octobre 2019, une nouvelle aventure a commencé lorsque la famille de Victoria a déménagé dans la ville vibrante de Seattle. Sans se décourager, elle a poursuivi son entraînement en ligne, prouvant que la distance n'était pas un obstacle pour une musicienne passionnée. Son grand coup d'éclat est arrivé le 29 mai 2022, lorsqu'elle a suivi une leçon d'essai à la légendaire école School of Rock. Alors que la plupart de ses pairs commençaient dans le programme débutant "Rock 101", le talent de Victoria a brillé, lui permettant d'obtenir une place convoitée dans le programme avancé "Performers Program". Depuis lors, elle a enflammé la scène, éblouissant le public lors de {shows} spectacles en direct avec son énergie contagieuse et sa présence scénique magnétique.

Mais ce n'est que le début ! Victoria n'est pas seulement une artiste de scène ; elle est également une sensation des réseaux sociaux, ayant enregistré des centaines de vidéos captivantes qui mettent en valeur son talent à la batterie. Le 13 juin 2024, elle a fait sensation en tant que batteuse invitée pour le célèbre compositeur italien Alberto Rigonni lors du Song Symposium pour l'album Nemesis Call – un témoignage de son ascension fulgurante !

Dans une étape importante de sa carrière, Victoria a signé un contrat d'endossement avec Soultone Cymbals le 12 septembre 2024, solidifiant ainsi sa place parmi l'élite des batteurs et propulsant son son à un niveau supérieur !

Comme si cela ne suffisait pas, Victoria a élargi ses horizons musicaux en apprenant le violon en classe de 4ème. Elle a rejoint le World Youth Orchestra le 13 mai 2024 et a eu l'honneur de se produire aux côtés du célèbre duo Black Violin au Paramount Theatre, envoûtant une foule de 2 000 personnes par son talent extraordinaire.

Son parcours ne s'est pas arrêté là ! Le 29 août 2024, elle a rejoint l'orchestre Ballard Civic en tant que percussionniste et a fait ses débuts à la Town Hall Seattle lors de la célébration de l'Indépendance mexicaine devant près de 1 000 fans enthousiastes.

Les distinctions de Victoria sont tout aussi impressionnantes que ses performances. Elle a remporté le Prix d'Or et la mention "Fantastic Technique" au London Young Musician Contest 2022 et a été célébrée avec le prix annuel Star Musician Award et le prix Outstanding Drummer Special Award au London Young Musician of the Year 2023.

Portée par une soif insatiable de croissance, Victoria s'entraîne quotidiennement, repoussant sans cesse les limites de ses capacités à la batterie et au violon. Avec ses yeux rivés sur un avenir musical exaltant, cette jeune artiste dynamique n'en est qu'à ses débuts. Préparez-vous à être inspiré par Victoria Acosta – là où le rythme rencontre la passion et où les rêves deviennent réalité !
	`
}

export default fr;
