import { Language } from './language';

const de = {
    code: Language.de,

	// HOME PAGE
	
	t_description: '{age} Jahre altes Schlagzeugermädchen',
	t_close: 'Schließen',

	b_about: 'Über mich',
	b_press: 'Drücken Sie',
	b_shows: 'Zeigt an',
	b_language: 'Deutsch',
	b_share: 'Aktie',

	t_english: 'Englisch',
	t_spanish: 'Spanisch',
	t_portuguese: 'Portugiesisch',
	t_italian: 'Italienisch',
	t_french: 'Französisch',
	t_german: 'Deutsch',
	t_chinese: 'Chinesisch',

	// SHOWS PAGE

	BlackViolinConcert_title: 'Schwarzes Violinkonzert mit The World Your Orchestra',
	BlackViolinConcert_description: 'Das Paramount Theater',

	MexicanIndependenceDay2024_title: 'Feier zum mexikanischen Unabhängigkeitstag mit dem Ballard Civic Orchestra',
	MexicanIndependenceDay2024_description: 'Rathaus Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Ballard Seafood Fest 2024',
	BallardSeaFoodFest2024_description: 'Ballard Seafood Fest',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Moderner Metal',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Saisonvorschau Winter 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Haarmetall',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Talentshow 2023',
	TalentShow2023_description: 'Grundschule Seattle Salmon Bay',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Saisonvorschau Frühjahr 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'The Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Soundtrack Show',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgie der 90er Jahre',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Talentshow 2022',
	TalentShow2022_description: 'Grundschule Seattle Salmon Bay',
	
	// PRESS PAGE

	the_daly_migs_show_interview_title: 'Interview mit der Daly Migs Show',
	the_daly_migs_show_interview_description: 'Interview über die Einladung zu Godsmack on Stage',

	alizee_defan_07_02_2024_title: '"Alizee Defan" interview',
	alizee_defan_07_02_2024_description: 'Eksklusivt interview',

	jaime_mendez_news_06_26_2024_title: '"Jaime Mendez Nieuws"-interview',
	jaime_mendez_news_06_26_2024_description: 'Lokaal Seattle-nieuws in het Spaans',

	bien_informado_03_22_2023_title: 'Artikel von "Bien Informado',
	bien_informado_03_22_2023_description: 'Artikel auf der argentinischen Nachrichtenseite über Victoria Drums',

	wave_potter_reaction_01_30_2023_title: 'Wave Potter Reaktion',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reagiert auf Victorias Cover von Lux Æterna von Metallica',

	// ABOUT PAGE

	b_back: 'Geh zurück',

	t_title: 'Victoria Acosta',
	t_pronouns: '(sie/ihr)',

	t_about: `
	Lernen Sie Victoria Acosta kennen, die mitreißende {age}-jährige Schlagzeugerin aus El Paso, Texas, deren lebhafter Geist und unermüdliche Leidenschaft für Musik die Welt im Sturm erobern! Victoria stammt aus Ciudad Juarez, Mexiko, und verbrachte ihre Kindheit in den geschäftigen Straßen von Guadalajara. Ihre Faszination für das Schlagzeug entfachte sie bereits im zarten Alter von vier Jahren. Während die meisten Kinder mit Spielzeug spielten, trommelte dieses bemerkenswerte Talent Beats und erklärte das Schlagzeug in der Familie zu ihrem Lieblingsinstrument.

Am 13. September 2018 wurden ihre Träume wahr, als sie ihr erstes Schlagzeug auspackte, und von diesem Moment an gab es kein Halten mehr! Sie schrieb sich an der renommierten Musicalerias Academy in Guadalajara ein und tauchte kopfüber in den formalen Unterricht ein, um die Kunst des Rhythmus zu meistern – wie ein echter Rockstar.

Am 10. Oktober 2019 begann ein neues Abenteuer, als Victorias Familie in die pulsierende Stadt Seattle zog. Unbeirrt setzte sie ihr Training online fort und bewies, dass Entfernung kein Hindernis für eine leidenschaftliche Musikerin ist. Ihr großer Durchbruch kam am 29. Mai 2022, als sie eine Probestunde an der legendären School of Rock nahm. Während die meisten ihrer Altersgenossen im Anfängerprogramm „Rock 101“ begannen, glänzte Victorias Talent und sicherte ihr einen begehrten Platz im fortgeschrittenen Performer-Programm. Seitdem erobert sie die Bühne im Sturm und begeistert das Publikum in {shows} Live-Shows mit ihrer ansteckenden Energie und ihrer magnetischen Bühnenpräsenz.

Aber das ist erst der Anfang! Victoria ist nicht nur eine Performerin, sondern auch eine Social-Media-Sensation, die Hunderte von fesselnden Videos aufgenommen hat, die ihr Können am Schlagzeug zeigen. Am 13. Juni 2024 sorgte sie als Gast-Schlagzeugerin für den renommierten italienischen Komponisten Alberto Rigonni im Song Symposium für das Album „Nemesis Call“ für Aufsehen – ein Beweis für ihren aufsteigenden Stern!

Ein aufregender Meilenstein war, als Victoria am 12. September 2024 einen Endorsement-Vertrag mit Soultone Cymbals unterzeichnete, was ihren Platz unter den Elite-Schlagzeugern festigte und ihren Sound auf die nächste Stufe brachte!

Als wäre das nicht genug, erweiterte Victoria ihre musikalischen Horizonte, indem sie in der 4. Klasse die Geige aufnahm. Am 13. Mai 2024 trat sie dem World Youth Orchestra bei und hatte die Ehre, zusammen mit dem renommierten Duo Black Violin im Paramount Theatre aufzutreten, wo sie eine Menge von 2.000 Menschen mit ihrem außergewöhnlichen Talent verzauberte.

Ihre Reise hörte hier nicht auf! Am 29. August 2024 trat sie als Schlagzeugerin dem Ballard Civic Orchestra bei und feierte ihr Debüt in der Town Hall Seattle während der Feier zum mexikanischen Unabhängigkeitstag vor fast 1.000 begeisterten Fans.

Victorias Auszeichnungen sind ebenso beeindruckend wie ihre Auftritte. Sie hat den Gold Prize und den Fantastic Technique Award beim London Young Musician Contest 2022 gewonnen und wurde mit dem Annual Star Musician Award sowie dem Annual Outstanding Drummer Special Award beim London Young Musician of the Year 2023 geehrt.

Angetrieben von einem unstillbaren Durst nach Wachstum übt Victoria täglich und erweitert kontinuierlich ihre Fähigkeiten am Schlagzeug und an der Geige. Mit ihrem Blick auf eine aufregende musikalische Zukunft steht diese dynamische junge Künstlerin erst am Anfang. Mach dich bereit, von Victoria Acosta inspiriert zu werden – dort, wo Rhythmus auf Leidenschaft trifft und Träume wahr werden!
`
}

export default de;
