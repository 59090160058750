import { Language } from './language';

const en = {
    code: Language.en,

	// HOME PAGE
	t_description: '{age} years old drummer girl',
	t_close: 'Close',

	b_about: 'About Me',
	b_press: 'Press',
	b_shows: 'Shows',
	b_language: 'English',
	b_share: 'Share',

	t_english: 'English',
	t_spanish: 'Spanish',
	t_portuguese: 'Portuguese',
	t_italian: 'Italian',
	t_french: 'French',
	t_german: 'German',
	t_chinese: 'Chinese',

	// SHOWS PAGE

	BlackViolinConcert_title: 'Black Violin Concert With The World Your Orchestra',
	BlackViolinConcert_description: 'The Paramount Theatre',

	MexicanIndependenceDay2024_title: 'Mexican Independence Day Celebration With The Ballard Civic Orchestra',
	MexicanIndependenceDay2024_description: 'Town Hall Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'The Paramount Theatre',

	BallardSeaFoodFest2024_title: 'Ballard Seafood Fest 2024',
	BallardSeaFoodFest2024_description: 'Ballard Seafood Fest',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Modern Metal',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Season Preview Winter 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: '2023 Talent Show',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Season Preview Spring 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'The Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Soundtrack Show',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: '90\'s Nostalgia',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: '2022 Talent Show',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',


	// PRESS PAGE

	the_daly_migs_show_interview_title: 'The Daly Migs Show Interview',
	the_daly_migs_show_interview_description: 'Interview About Getting invited to join Godsmack on Stage',

	alizee_defan_07_02_2024_title: '"Alizee Defan" Interview',
	alizee_defan_07_02_2024_description: 'Exclusive interview',

	jaime_mendez_news_06_26_2024_title: '"Jaime Mendez News" Interview',
	jaime_mendez_news_06_26_2024_description: 'Local Seattle News in spanish',
	
	bien_informado_03_22_2023_title: '"Bien Informado" Article',
	bien_informado_03_22_2023_description: 'Argentina News Website Article about Victoria Drums',

	wave_potter_reaction_01_30_2023_title: 'Wave Potter Reaction',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reacts to Victoria\'s cover of Lux Æterna by Metallica',

	// ABOUT PAGE

	b_back: 'Go Back',
	
	t_title: 'Victoria Acosta',
	t_pronouns: '(she/her)',

	t_about: `
Meet Victoria Acosta, the electrifying {age}-year-old drummer from El Paso, Texas, whose vibrant spirit and relentless passion for music are taking the world by storm! With roots in Ciudad Juarez, Mexico, and a childhood spent in the bustling streets of Guadalajara, Victoria’s fascination for the drums ignited at the tender age of four. While most kids were busy playing with toys, this remarkable talent was busy tapping out beats and declaring the drums her favorite instrument to her family.

On September 13, 2018, her dreams came true when she unwrapped her very first drum kit, and from that moment on, there was no stopping her! Enrolling at the prestigious Musicalerias Academy in Guadalajara, she dove headfirst into formal lessons, mastering the art of rhythm like a true rock star.

In October 10, 2019, a new adventure began when Victoria's family relocated to the vibrant city of Seattle. Undeterred, she took her training online, proving that distance is no barrier for a passionate musician. Her big breakthrough came on May 29, 2022, when she took a trial lesson at the iconic School of Rock. While most of her peers began in the beginner Rock 101 Program, Victoria’s talent shone through, landing her a coveted spot in the advanced Performers Program. Since then, she has taken the stage by storm, dazzling audiences in {shows} live shows with her infectious energy and magnetic stage presence.

But that’s only the beginning! Victoria is not just a performer; she’s a social media sensation, having recorded hundreds of captivating videos that showcase her drumming prowess. On June 13, 2024, she made waves as a guest drummer for the acclaimed Italian composer Alberto Rigonni in the Song Symposium for the album Nemesis Call—a testament to her rising star status!

In a thrilling milestone, Victoria signed an endorsement agreement with Soultone Cymbals on September 12, 2024, solidifying her place among the drumming elite and taking her sound to the next level!

As if that wasn't enough, Victoria expanded her musical horizons by picking up the violin in 4th grade. Joining the World Youth Orchestra on May 13, 2024, she had the honor of performing alongside the renowned duo Black Violin at the Paramount Theatre, mesmerizing a crowd of 2,000 with her extraordinary talent.

Her journey didn’t stop there! On August 29, 2024, she joined the Ballard Civic Orchestra as a percussionist, debuting at the Town Hall Seattle during the Mexican Independence Day Celebration in front of nearly 1,000 enthusiastic fans.

Victoria’s accolades are as impressive as her performances. She’s bagged the Gold Prize and Fantastic Technique at the London Young Musician Contest 2022 and was celebrated with the Annual Star Musician Award and the Annual Outstanding Drummer Special Award at The London Young Musician of the Year 2023.

Driven by an unquenchable thirst for growth, Victoria practices daily, pushing the boundaries of her drumming and violin abilities. With her eyes set on an exhilarating future in music, this dynamic young artist is just getting started. Get ready to be inspired by Victoria Acosta—where rhythm meets passion and dreams come alive!
`
}

export default en;
