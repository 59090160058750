import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './styles/index.scss';
import Home from './views/Home';
import About from './views/About';
import Press from './views/Press';
import Shows from './views/Shows';
import reportWebVitals from './reportWebVitals';
import Localization from './localization';

const rootElement = document.getElementById('root');

const config = require('./config/web-site');

const root = ReactDOM.createRoot(
  rootElement as HTMLElement
);
  
Localization.setLanguage();
const router = createBrowserRouter([{
  path: config.pages.home.route,
  element: <Home />
},{
  path: config.pages.about.route,
  element: <About />
},{
  path: config.pages.shows.route,
  element: <Shows />
},{
  path: config.pages.press.route,
  element: <Press />
},{
  path: '*',
  element: <Home />
}]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
