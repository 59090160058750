import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { formatDate } from '../utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Press.scss';

type CardProps = {
	title: string;
	description: string;
	image: string;
	date?: string;
	url?: string;
  testid?: string;
  clickHandler?: () => void;
}

function CustomCard (props: CardProps) {
	const { title, description, image, date, url, clickHandler, testid } = props;

	return (
    <Card>
      {
        url ?
          <Link to={url} target='_blank' onClick={clickHandler} data-testid={testid}>
            <Card.Img variant="top" src={image} />
          </Link> 
        : <Card.Img variant="top" src={image} />
      }
      
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        { date && <Card.Subtitle className="mb-1">{formatDate(date)}</Card.Subtitle>}
        <Card.Text>
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
	);
}

export default CustomCard;