import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import NavBar, { Buttons } from '../components/NavBar';
import LangNavBar from '../components/LangNavBar';
import CustomCard from '../components/CustomCard';
import Localization from '../localization';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Press.scss';

const config = require('../config/web-site');
const articles = Object.keys(config.press);

const strings = Localization.getStrings();

const renderArticles = () =>
  articles.map( article => (
    <Col xs='12' sm='6' key={article}>
      <CustomCard
        title={strings[`${article}_title`]}
        description={strings[`${article}_description`]}
        image={require(`../assets/img/press/${config.press[article].image}`)}
        date={config.press[article].date}
        url={config.press[article].url}
        clickHandler={() => {
          ReactGA.event({
            category: config.analytics.parameters.categories.navigation,
            action: `${article} Article Click`,
            label: 'press article'
          });
        }}
        testid={article}
      />
    </Col>
  ));

function Press() {
  ReactGA.send({ 
    hitType: config.analytics.parameters.hitType, 
    page: config.pages.press.route, 
    title: config.pages.press.title 
   });
  return (
    <div className='general-container'>
      <div className='nav-left'><NavBar buttons={[Buttons.back]}></NavBar></div>
      <div className='nav-right'><LangNavBar></LangNavBar></div>
      <Container fluid className='press'>
        <Row key='row-title'>
          <Col> <h1 className='press-title'>{strings.b_press}</h1></Col>
        </Row>
        <Row key='row-press'>
          { renderArticles() }
        </Row>
      </Container>
    </div>
  );
}

export default Press;
