import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavDropdown } from 'react-bootstrap';
import { MDBIcon } from 'mdb-react-ui-kit';

import Localization from '../localization';
import { Language as Lang } from '../lang/language';
import ReactGA from 'react-ga4';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';

const config = require('../config/web-site');

function LangNavBar() {
  const strings = Localization.getStrings();

  const getLanguageString = (lang: Lang) => {
    if(lang === Lang.es) {
      return strings.t_spanish;
    }
    if(lang === Lang.pt) {
      return strings.t_portuguese;
    }
    if(lang === Lang.it) {
      return strings.t_italian;
    }
    if(lang === Lang.fr) {
      return strings.t_french;
    }
    if(lang === Lang.de) {
      return strings.t_german;
    }
    if(lang === Lang.zh) {
      return strings.t_chinese;
    }
    return strings.t_english;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (event: any) => {
    ReactGA.event({
      category: config.analytics.parameters.categories.language,
      action: `Selected Language: ${getLanguageString(event)}`,
      label: 'language'
    });
    Localization.setLanguage(event as Lang);
    window.location.reload();
  }

  const setLanguageWithFlag = () => {
    let icon = <MDBIcon flag='us' />;
    if(Localization.getLanguage() === Lang.es) {
      icon = <MDBIcon flag='mx' />
    }
    if(Localization.getLanguage() === Lang.pt) {
      icon = <MDBIcon flag='br' />
    }
    if(Localization.getLanguage() === Lang.it) {
      icon = <MDBIcon flag='it' />
    }
    if(Localization.getLanguage() === Lang.fr) {
      icon = <MDBIcon flag='fr' />
    }
    if(Localization.getLanguage() === Lang.de) {
      icon = <MDBIcon flag='de' />
    }
    if(Localization.getLanguage() === Lang.zh) {
      icon = <MDBIcon flag='china' />
    }
    return <span>{icon}<span>{strings.b_language}</span></span>
  }

	return (
  <Nav className='justify-content-end' onSelect={handleSelect}>

      <NavDropdown title={setLanguageWithFlag()} data-testid='language-dropdown'>
      { Localization.getLanguage() != Lang.en && 
        <NavDropdown.Item eventKey={Lang.en} data-testid='lang-option-en'>
          <MDBIcon flag='us' />
          { strings.t_english }
        </NavDropdown.Item>
      }
      { Localization.getLanguage() != Lang.es &&
        <NavDropdown.Item eventKey={Lang.es} data-testid='lang-option-es'>
          <MDBIcon flag='mx' />
          { strings.t_spanish }
        </NavDropdown.Item>
      }
      { Localization.getLanguage() != Lang.pt &&
        <NavDropdown.Item eventKey={Lang.pt} data-testid='lang-option-pt'>
        <MDBIcon flag='br' />
          { strings.t_portuguese }
        </NavDropdown.Item>
      }
      { Localization.getLanguage() != Lang.it &&
        <NavDropdown.Item eventKey={Lang.it} data-testid='lang-option-it'>
        <MDBIcon flag='it' />
          { strings.t_italian }
        </NavDropdown.Item>
      }
      { Localization.getLanguage() != Lang.fr &&
        <NavDropdown.Item eventKey={Lang.fr} data-testid='lang-option-fr'>
        <MDBIcon flag='fr' />
          { strings.t_french }
        </NavDropdown.Item>
      }
      { Localization.getLanguage() != Lang.de &&
        <NavDropdown.Item eventKey={Lang.de} data-testid='lang-option-de'>
        <MDBIcon flag='de' />
          { strings.t_german }
        </NavDropdown.Item>
      }
      { Localization.getLanguage() != Lang.zh &&
        <NavDropdown.Item eventKey={Lang.zh} data-testid='lang-option-zh'>
        <MDBIcon flag='china' />
          { strings.t_chinese }
        </NavDropdown.Item>
      }
      </NavDropdown>
    </Nav>
	);
}

export default LangNavBar;
