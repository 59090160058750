import { Language } from './language';

const it = {
    code: Language.it,

	// HOME PAGE

	t_description: 'Batterista ragazza, {age} anni',
	t_close: 'Vicino',

	b_about: 'Su di me',
	b_press: 'Premere',
	b_shows: 'Spettacoli',
	b_language: 'Italiano',
	b_share: 'Condividere',

	t_english: 'Inglese',
	t_spanish: 'Spagnolo',
	t_portuguese: 'Portoghese',
	t_italian: 'Italiano',
	t_french: 'Francese',
	t_german: 'Tedesco',
	t_chinese: 'Cinese',

	// SHOWS PAGE

	BlackViolinConcert_title: 'Concerto di violino nero con The World Your Orchestra',
	BlackViolinConcert_description: 'Il Teatro Paramount',

	MexicanIndependenceDay2024_title: 'Celebrazione dell\'Indipendenza del Messico con l\'Orchestra Civica Ballard',
	MexicanIndependenceDay2024_description: 'Municipio di Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival dei frutti di mare Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival dei frutti di mare Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',
	
	ModernMetal_title: 'Metallo moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Anteprima stagione inverno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Metallo per capelli',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Talent Show 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Anteprima di stagione primavera 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'I Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Spettacolo di colonne sonore',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia anni \'90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Talent Show 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	the_daly_migs_show_interview_title: 'Intervista con The Daly Migs Show',
	the_daly_migs_show_interview_description: 'Intervista sull\'essere invitato a salire sul palco con i Godsmack',

	alizee_defan_07_02_2024_title: 'Intervista con "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Intervista esclusiva',

	jaime_mendez_news_06_26_2024_title: 'Intervista a "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Notizie locali di Seattle in spagnolo',

	bien_informado_03_22_2023_title: 'Articolo di "Bien Informado"',
	bien_informado_03_22_2023_description: 'Articolo del sito di notizie argentino su Victoria',

	wave_potter_reaction_01_30_2023_title: 'La reazione di Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reagisce alla copertina di Victoria Lux Æterna dei Metallica',
	
	// ABOUT PAGE

	b_back: 'Torna indietro',

	t_title: 'Victoria Acosta',
	t_pronouns: '(lei)',

	t_about: `
Conosci Victoria Acosta, l'elettrizzante batterista di {age} anni di El Paso, Texas, con la sua vibrante energia e la sua passione imparabile per la musica che sta conquistando il mondo! Con radici a Ciudad Juárez, in Messico, e un'infanzia nelle bulliciose calles di Guadalajara, il fascino di Victoria per la batteria è iniziato in quattro anni. Mentre la maggioranza dei bambini giocava con i giocattoli, questo talento straordinario stava già marcando ritmi e dichiarando alla sua famiglia che la batteria era il suo strumento preferito.

Il 13 settembre 2018, i suoi sogni si sono avverati quando ha aperto il suo primo set di batteria, e da quel momento nulla l'ha più fermata! Iscrivendosi alla prestigiosa Accademia Musicalerías di Guadalajara, si è immersa completamente nelle lezioni formali, padroneggiando l'arte del ritmo come una vera star del rock.

Il 10 ottobre 2019, è iniziata una nuova avventura quando la famiglia di Victoria si è trasferita nella vivace città di Seattle. Senza scoraggiarsi, ha continuato la sua formazione online, dimostrando che la distanza non è un ostacolo per una musicista appassionata. La sua grande opportunità è arrivata il 29 maggio 2022, quando ha partecipato a una lezione di prova presso l'iconica School of Rock. Mentre la maggior parte dei suoi coetanei iniziava nel programma Rock 101 per principianti, il talento di Victoria ha brillato, assicurandole un posto ambito nel programma avanzato Performers. Da allora, ha incantato il pubblico in {shows} spettacoli dal vivo con la sua energia contagiosa e la sua presenza scenica magnetica.

E questo è solo l'inizio! Victoria non è solo una performer, è anche una sensazione sui social media, avendo registrato centinaia di video accattivanti che mostrano la sua maestria alla batteria. Il 13 giugno 2024, ha fatto scalpore come batterista ospite dell'acclamato compositore italiano Alberto Rigoni nel Song Symposium per l'album Nemesis Call, una testimonianza del suo status di stella nascente!

In una tappa emozionante, Victoria ha firmato un contratto di sponsorizzazione con Soultone Cymbals il 12 settembre 2024, consolidando il suo posto tra l'élite dei batteristi e portando il suo suono a un livello successivo.

E come se non bastasse, Victoria ha ampliato i suoi orizzonti musicali imparando a suonare il violino in 4ª elementare! Unendosi alla World Youth Orchestra il 13 maggio 2024, ha avuto l'onore di esibirsi insieme al rinomato duo Black Violin al Paramount Theatre, affascinando un pubblico di 2.000 persone con il suo straordinario talento.

Il suo viaggio non è finito qui! Il 29 agosto 2024, si è unita alla Ballard Civic Orchestra come percussionista, debuttando al Town Hall di Seattle durante la celebrazione del Giorno dell'Indipendenza Messicana davanti a quasi 1.000 fan entusiasti.

I successi di Victoria sono impressionanti quanto le sue esibizioni. Ha vinto il Premio d'Oro e la Menzione per la Tecnica Fantastica al concorso London Young Musician 2022 ed è stata celebrata con il Premio Annuale alla Star della Musica e il Premio Speciale al Batterista Eccezionale al London Young Musician of the Year 2023.

Guidata da una sete insaziabile di crescita, Victoria si esercita quotidianamente, spingendo i limiti delle sue capacità sia con la batteria che con il violino. Con lo sguardo rivolto a un futuro emozionante nella musica, questa giovane artista dinamica è solo all'inizio. Preparati a lasciarti ispirare da Victoria Acosta, dove il ritmo incontra la passione e i sogni prendono vita!`
};

export default it;
