import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import NavBar, { Buttons } from '../components/NavBar';
import LangNavBar from '../components/LangNavBar';
import CustomCard from '../components/CustomCard';
import Localization from '../localization';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Press.scss';

const config = require('../config/web-site');
const shows = Object.keys(config.shows);

const strings = Localization.getStrings();

const renderShows = () =>
  shows.map(show => (
    <Col xs='12' sm='6' key={show}>
      <CustomCard
        title={strings[`${show}_title`]}
        description={strings[`${show}_description`]}
        image={require(`../assets/img/shows/${config.shows[show].image}`)}
        date={config.shows[show].date}
      />
    </Col>
  ));

function Shows() {
  ReactGA.send({ 
    hitType: config.analytics.parameters.hitType, 
    page: config.pages.shows.route, 
    title: config.pages.shows.title
   });
  return (
    <div className='general-container'>
      <div className='nav-left'><NavBar buttons={[Buttons.back]}></NavBar></div>
      <div className='nav-right'><LangNavBar></LangNavBar></div>
      <Container fluid className='press'>
        <Row key='row-title'>
          <Col><h1 className='press-title'>{strings.b_shows}</h1></Col>
        </Row>
        <Row key='row-press'>
          { renderShows() }
        </Row>
      </Container>
    </div>
  );
}

export default Shows;
