import React from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import { BsNewspaper } from 'react-icons/bs';
import { GiDrumKit, GiMusicalNotes } from 'react-icons/gi';
import { IoPersonCircle } from 'react-icons/io5';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row } from 'react-bootstrap';
import NavBar, { Buttons } from '../components/NavBar';
import LangNavBar from '../components/LangNavBar';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Home.scss';

import Localization from '../localization';

import logo from '../assets/img/logo.png';

const config = require('../config/web-site');

const buildMainMenuButton = (
  key: string, 
  testID: string, 
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler: (e: any) => void, 
  title: string,
  icon: JSX.Element, 
  className?: string
) => {
  return (
    <li key={key}>
      <Button
        data-testid={testID}
        variant='primary'
        size='lg'
        className={className}
        onClick={handler}
      >
        { icon }
        { title }
      </Button>
    </li>
  )
}

const setSocialButtons = () => {
  const buttons = [];
  for(const social in config.social) {
    buttons.push(
      buildMainMenuButton(
        social, 
        `${social}-button`, 
        handleClick,
        config.social[social].title, 
        setButtonIcon(social), 
        social
      )
    );
  }
  return buttons;
}

const setButtonIcon = (key: string) => {
  switch(key) {
    case 'soultone':
      return <GiDrumKit size={30} className='icon' />;
    case 'instagram':
      return <FaInstagram size={30} className='icon' />;
    case 'facebook':
      return <FaFacebook size={30} className='icon' />;
    case 'tiktok':
      return <FaTiktok size={30} className='icon' />;
    default:
      return <FaYoutube size={30} className='icon' />;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleClick = (event: any) => {
  event.preventDefault();
  const style = event.target.getAttribute('class');

  for(const social in config.social) {
    if(style.includes(social)) {
      ReactGA.event({
        category: config.analytics.parameters.categories.navigation,
        action: `${social} Click`,
        label: social
      });
      window.open(
        config.social[social as keyof typeof config.social].url, 
        '_blank'
      );
    }
  }
};

function Home() {
  const strings = Localization.getStrings();
  const navigate = useNavigate(); 
  ReactGA.initialize(
    config.analytics['tracking-id'], { 
      testMode: process.env.NODE_ENV != 'production' 
    }
  );
  ReactGA.send({ 
    hitType: config.analytics.parameters.hitType, 
    page: config.pages.home.route, 
    title: config.pages.home.title 
   });
  return (
    <div className='general-container'>
      <div className='nav-left'><NavBar buttons={[Buttons.share]}></NavBar></div>
      <div className='nav-right'><LangNavBar></LangNavBar></div>
      <Container fluid>
        <Row>
          <Col>
            <img src={logo} alt={config.title} className='logo' />
          </Col>
        </Row>
        <Row>
          <Col>
            <ul className='links-list'>
              {
              buildMainMenuButton(
                'about', 
                'about-button',
                () => {
                  ReactGA.event({
                    category: config.analytics.parameters.categories.navigation,
                    action: 'About Me Click',
                    label: 'about'
                  });
                  navigate(config.pages.about.route);
                },
                strings.b_about,
                <IoPersonCircle size={32} className='icon' />
                )
              }
              {
                buildMainMenuButton(
                  'shows', 
                  'shows-button',
                  () => {
                    ReactGA.event({
                      category: config.analytics.parameters.categories.navigation,
                      action: 'Shows Click',
                      label: 'shows'
                    });
                    navigate(config.pages.shows.route);
                  },
                  strings.b_shows,
                  <GiMusicalNotes size={32} className='icon' />
                )
              }
              {
                buildMainMenuButton(
                  'press', 
                  'press-button',
                  () => {
                    ReactGA.event({
                      category: config.analytics.parameters.categories.navigation,
                      action: 'Press Click',
                      label: 'press'
                    });
                    navigate(config.pages.press.route);
                  },
                  strings.b_press,
                  <BsNewspaper size={32} className='icon' />
                  )
                }
              { setSocialButtons() }
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
