import { Language } from './language';

const pt = {
    code: Language.pt,

	// HOME PAGE

	t_description: 'Menina baterista, {age} anos',
	t_close: 'Fechar',
	
	b_about: 'Sobre mim',
	b_press: 'Imprensa',
	b_shows: 'Shows',
	b_language: 'Português',
	b_share: 'Compartilhar',

	t_english: 'Inglês',
	t_spanish: 'Espanhol',
	t_portuguese: 'Português',
	t_italian: 'Italiano',
	t_french :'Francês',
	t_german: 'Alemão',
	t_chinese: 'Chinês',

	// SHOWS PAGE

	BlackViolinConcert_title: 'Concerto de Violino Negro com The World Your Orchestra',
	BlackViolinConcert_description: 'O Teatro Paramount',

	MexicanIndependenceDay2024_title: 'Celebração da Independência do México com a Orquestra Cívica Ballard',
	MexicanIndependenceDay2024_description: 'Prefeitura de Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival de Frutos do Mar Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival de Frutos do Mar Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Metal Moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Prévia da Temporada Inverno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Show de Talentos 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	SeasonPreviewSpring2023_title: 'Prévia da Temporada Primavera de 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Os Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Show da Trilha Sonora',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia dos anos 90',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Show de Talentos 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	the_daly_migs_show_interview_title: 'Entrevista com The Daly Migs Show',
	the_daly_migs_show_interview_description: 'Entrevista sobre ser convidado para subir ao palco com Godsmack',

	alizee_defan_07_02_2024_title: 'Entrevista com "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Entrevista exclusiva',

	jaime_mendez_news_06_26_2024_title: 'Entrevista "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Notícias locais de Seattle em espanhol',

	bien_informado_03_22_2023_title: 'Artigo de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Artigo do site de notícias argentino sobre Victoria',

	wave_potter_reaction_01_30_2023_title: 'A reação de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reage ao cover de Victoria Lux Æterna do Metallica',
	
	// ABOUT PAGE

	b_back: 'Volte',

	t_title: 'Victoria Acosta',
	t_pronouns: '(ela)',
	
	t_about: `
Conheça Victoria Acosta, a eletrizante baterista de {age} anos de El Paso, Texas, que tem uma energia vibrante e uma paixão incomparável pela música que está conquistando o mundo! Com raízes em Ciudad Juárez, México, e uma infância nas chamadas bulliciosas de Guadalajara, o fascínio de Victoria pela bateria começou em quatro anos. Enquanto a maioria das crianças brincavam com brinquedos, esse talento extraordinário se estabeleceu marcando ritmos e declarando à sua família que a bateria era seu instrumento favorito.

Em 13 de setembro de 2018, seus sonhos se tornaram realidade quando ela abriu seu primeiro kit de bateria, e a partir desse momento nada a parou! Ao se inscrever na prestigiada Academia Musicalerías em Guadalajara, ela mergulhou de cabeça nas aulas formais, dominando a arte do ritmo como uma verdadeira estrela do rock.

Em 10 de outubro de 2019, começou uma nova aventura quando a família de Victoria se mudou para a vibrante cidade de Seattle. Sem se desanimar, ela continuou sua formação online, provando que a distância não é um obstáculo para uma musicista apaixonada. Sua grande oportunidade chegou em 29 de maio de 2022, quando ela fez uma aula experimental na icônica School of Rock. Enquanto a maioria de seus colegas começava no programa Rock 101 para iniciantes, o talento de Victoria brilhou, garantindo-lhe um cobiçado lugar no programa avançado Performers. Desde então, ela tem deslumbrado o público em {shows} shows ao vivo com sua energia contagiante e presença de palco magnética.

E isso é apenas o começo! Victoria não é apenas uma intérprete; ela também é uma sensação nas redes sociais, tendo gravado centenas de vídeos cativantes que mostram sua destreza na bateria. Em 13 de junho de 2024, ela causou sensação como baterista convidada do aclamado compositor italiano Alberto Rigoni no Song Symposium para o álbum Nemesis Call, um testemunho de seu status de estrela em ascensão!

Em um marco emocionante, Victoria assinou um contrato de patrocínio com a Soultone Cymbals em 12 de setembro de 2024, consolidando seu lugar entre a elite dos bateristas e elevando seu som a outro nível.

E como se não bastasse, Victoria expandiu seus horizontes musicais ao aprender a tocar violino na 4ª série! Ao se juntar à World Youth Orchestra em 13 de maio de 2024, ela teve a honra de se apresentar ao lado do renomado duo Black Violin no Paramount Theatre, fascinando uma plateia de 2.000 pessoas com seu talento extraordinário.

Sua jornada não parou por aí! Em 29 de agosto de 2024, ela se juntou à Ballard Civic Orchestra como percussionista, estreando no Town Hall de Seattle durante a celebração do Dia da Independência do México, diante de quase 1.000 fãs entusiasmados.

As conquistas de Victoria são tão impressionantes quanto suas apresentações. Ela ganhou o Prêmio de Ouro e a Menção de Técnica Fantástica no Concurso London Young Musician 2022 e foi celebrada com o Prêmio Anual de Estrela da Música e o Prêmio Especial de Baterista Excepcional no London Young Musician of the Year 2023.

Impulsionada por uma sede insaciável de crescimento, Victoria pratica diariamente, empurrando os limites de suas habilidades com a bateria e o violino. Com os olhos voltados para um futuro emocionante na música, esta jovem artista dinâmica está apenas começando. Prepare-se para se inspirar com Victoria Acosta, onde o ritmo encontra a paixão e os sonhos se tornam realidade!
	`
};

export default pt;
