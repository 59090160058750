import { Language } from './language';

const es = {
    code: Language.es,

	// HOME PAGE

	t_description: 'Niña baterista, {age} años de edad',
	t_close: 'Cerrar',

	b_about: 'Acerca de mi',
	b_press: 'Prensa',
	b_shows: 'Shows',
	b_language: 'Español',
	b_share: 'Compartir',

	t_english: 'Inglés',
	t_spanish: 'Español',
	t_portuguese: 'Portugués',
	t_italian: 'Italiano',
	t_french: 'Francés',
	t_german: 'Alemán',
	t_chinese: 'Chino',

	// SHOWS PAGE

	BlackViolinConcert_title: 'Concierto Black Violin con The World Your Orchestra',
	BlackViolinConcert_description: 'Teatro The Paramount',

	MexicanIndependenceDay2024_title: 'Celebracion de la Independencia de Mexico con La Orquesta Civica de Ballard',
	MexicanIndependenceDay2024_description: 'Ayuntamiento de Seattle',

	BluesRock_title: 'Blues Rock',
	BluesRock_description: 'School of Rock Seattle',

	BallardSeaFoodFest2024_title: 'Festival de comida del mar en Ballard 2024',
	BallardSeaFoodFest2024_description: 'Festival de comida del mar en Ballard',

	Funk_title: 'Funk',
	Funk_description: 'School of Rock Seattle',

	Heart_title: 'Heart',
	Heart_description: 'School of Rock Seattle',

	ModernMetal_title: 'Metal Moderno',
	ModernMetal_description: 'School of Rock Seattle',

	SeasonPreviewWinter2023_title: 'Concierto de avance de invierno 2023',
	SeasonPreviewWinter2023_description: 'School of Rock Seattle',

	SmashingPumpkins_title: 'Smashing Pumpkins',
	SmashingPumpkins_description: 'School of Rock Seattle',

	Nirvana_title: 'Nirvana',
	Nirvana_description: 'School of Rock Seattle',

	HairMetal_title: 'Hair Metal',
	HairMetal_description: 'School of Rock Seattle',

	Deftones_title: 'Deftones',
	Deftones_description: 'School of Rock Seattle',

	TalentShow2023_title: 'Show de Talentos 2023',
	TalentShow2023_description: 'Seattle Salmon Bay K-8',

	SeasonPreviewSpring2023_title: 'Concierto de avance de primavera 2023',
	SeasonPreviewSpring2023_description: 'School of Rock Seattle',

	Ramones_title: 'Los Ramones',
	Ramones_description: 'School of Rock Seattle',

	Soundtrack_title: 'Show de Soundtracks',
	Soundtrack_description: 'School of Rock Seattle',

	PopPunkVsEmo_title: 'Pop Punk Vs. Emo',
	PopPunkVsEmo_description: 'School of Rock Seattle',

	_90sNostalgia_title: 'Nostalgia de los 90\'s',
	_90sNostalgia_description: 'School of Rock Seattle',

	TalentShow2022_title: 'Show de Talentos 2022',
	TalentShow2022_description: 'Seattle Salmon Bay K-8',

	// PRESS PAGE

	the_daly_migs_show_interview_title: 'Entrevista con The Daly Migs Show',
	the_daly_migs_show_interview_description: 'Entrevista acerca de ser invitada a subir al escenario con Godsmack',

	alizee_defan_07_02_2024_title: 'Entrevista con "Alizee Defan"',
	alizee_defan_07_02_2024_description: 'Entrevista exclusiva',

	jaime_mendez_news_06_26_2024_title: 'Entrevista con "Jaime Mendez News"',
	jaime_mendez_news_06_26_2024_description: 'Noticiero local de Seattle en español',

	bien_informado_03_22_2023_title: 'Artículo de "Bien Informado"',
	bien_informado_03_22_2023_description: 'Artículo de Sitio de Noticias Argentino acerca de Victoria',

	wave_potter_reaction_01_30_2023_title: 'Reacción de Wave Potter',
	wave_potter_reaction_01_30_2023_description: 'Wave Potter reacciona al cover de Victoria Lux Æterna de Metallica',
	
	// ABOUT PAGE

	b_back: 'Regresar',

	t_title: 'Victoria Acosta',
	t_pronouns: '(ella)',

	t_about: `
Conoce a Victoria Acosta, la electrizante baterista de {age} años de El Paso, Texas, ¡cuya vibrante energía y pasión imparable por la música están conquistando el mundo! Con raíces en Ciudad Juárez, México, y una infancia en las bulliciosas calles de Guadalajara, la fascinación de Victoria por la batería comenzó a los cuatro años. Mientras la mayoría de los niños jugaban con juguetes, este talento extraordinario ya estaba marcando ritmos y declarando a su familia que la batería era su instrumento favorito.

El 13 de septiembre de 2018, sus sueños se hicieron realidad cuando abrió su primer set de batería, ¡y desde ese momento nada la detuvo! Al inscribirse en la prestigiosa Academia Musicalerías en Guadalajara, se sumergió de lleno en las clases formales, dominando el arte del ritmo como una verdadera estrella del rock.

El 10 de octubre de 2019, comenzó una nueva aventura cuando la familia de Victoria se mudó a la vibrante ciudad de Seattle. Sin desanimarse, continuó su formación en línea, demostrando que la distancia no es un obstáculo para una músico apasionada. Su gran oportunidad llegó el 29 de mayo de 2022, cuando tomó una clase de prueba en la icónica School of Rock. Mientras la mayoría de sus compañeros comenzaban en el programa Rock 101 para principiantes, el talento de Victoria brilló, lo que le aseguró un lugar codiciado en el programa avanzado Performers. Desde entonces, ha deslumbrado al público en {shows} espectáculos en vivo con su energía contagiosa y su magnética presencia escénica.

¡Y eso es solo el comienzo! Victoria no es solo una intérprete; también es una sensación en las redes sociales, habiendo grabado cientos de videos cautivadores que muestran su destreza con la batería. El 13 de junio de 2024, causó sensación como baterista invitada del aclamado compositor italiano Alberto Rigoni en la canción Symposium para el álbum Nemesis Call, ¡un testimonio de su estatus de estrella en ascenso!

En un hito emocionante, Victoria firmó un acuerdo de patrocinio con Soultone Cymbals el 12 de septiembre de 2024, consolidando su lugar entre la élite de la batería y llevando su sonido al siguiente nivel.

¡Y por si fuera poco, Victoria expandió sus horizontes musicales al aprender a tocar el violín en 4º grado! Al unirse a la World Youth Orchestra el 13 de mayo de 2024, tuvo el honor de actuar junto al renombrado dúo Black Violin en el Paramount Theatre, fascinando a una audiencia de 2,000 personas con su extraordinario talento.

¡Su viaje no terminó ahí! El 29 de agosto de 2024, se unió a la Ballard Civic Orchestra como percusionista, debutando en el Town Hall de Seattle durante la celebración del Día de la Independencia de México frente a casi 1,000 fanáticos entusiastas.

Los logros de Victoria son tan impresionantes como sus actuaciones. Ha ganado el Premio de Oro y la Mención de Técnica Fantástica en el Concurso London Young Musician 2022, y fue celebrada con el Premio Anual a la Estrella de la Música y el Premio Especial de Baterista Sobresaliente en el London Young Musician of the Year 2023.

Impulsada por una sed insaciable de crecimiento, Victoria practica a diario, empujando los límites de sus habilidades con la batería y el violín. Con la mirada puesta en un futuro emocionante en la música, esta joven artista dinámica apenas está comenzando. ¡Prepárate para inspirarte con Victoria Acosta, donde el ritmo se encuentra con la pasión y los sueños cobran vida!
`
};

export default es;